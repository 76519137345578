/*----------------------------------------------------*/
/*  Scroll to
/*----------------------------------------------------*/
    function juizScrollTo(element){
        $(element).click(function(){
            var goscroll = false;
            var the_hash = $(this).attr("href");
            var regex = new RegExp("\#(.*)","gi");
            var the_element = '';
     
            if(the_hash.match("\#(.+)")) {
                the_hash = the_hash.replace(regex,"$1");
     
                if($("#"+the_hash).length>0) {
                    the_element = "#" + the_hash;
                    goscroll = true;
                }
                else if($("a[name=" + the_hash + "]").length>0) {
                    the_element = "a[name=" + the_hash + "]";
                    goscroll = true;
                }
     
                if(goscroll) {
                    $('html, body').animate({
                        scrollTop:$(the_element).offset().top - 145
                    }, 'slow');
                    return false;
                }
            }
        });
    };
    
    juizScrollTo('a[href^="#"]');


/*----------------------------------------------------*/
/*  Menu toggle
/*----------------------------------------------------*/
    $("#menu-toggle").click(function(e) {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
    });

    $("#menu-toggle2").click(function(e) {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
    });


/*----------------------------------------------------*/
/*  Content toggle
/*----------------------------------------------------*/
    $(".toggle").on('click', function() {
        var id = $(this).parent('div').children('p:not(:first-child)');
        console.log(id);

        if($(id).is(":hidden")) {
            $(this).text('→ Fermer');

            $(id).slideDown( "slow" );
        } else {
            $(this).text('→ Lire plus');

            $(id).slideUp();
        }
    });











